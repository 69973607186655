<template>
  <div class="m-auto w-1/3 mt-64">
    <LanguageForm :model="model" endpoint="/api/1.0/language/create" title="Create Language" label="Create Language"/>
  </div>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator'
import LanguageForm from '@/views/language/form/LanguageForm'

@Component({
  components: { LanguageForm }
})
export default class CreateLanguage extends Vue {
  model = {
    name: '',
    code: null
  }
}
</script>
