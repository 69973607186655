




















import { Vue, Component, Prop } from 'vue-property-decorator'
import { Functions, Form, Card, HttpPost, Textbox, Button, Validation, Dropdown, Label } from '../../../lib/mavejs'
import { codes } from '@/classes/Codes'
import { url } from '@/functions'

@Component({
  components: { Card, HttpPost, Textbox, Button, Validation, Dropdown, Label },
  mixins: [Functions, Form],
  methods: { url: url, codes: codes }
})
export default class LanguageForm extends Vue {
  @Prop() endpoint!: string
  @Prop() title!: string
  @Prop() label!: string
  @Prop() model!: object
  @Prop({ default: 'Form submitted' }) message!: string
  @Prop({ default: '/languages' }) redirect!: string

  language = this.model;

  response = {};
  isSuccessFull = true;

  $refs: {
    submit: Button;
  }

  handleResponse (fetch) {
    this.$refs.submit.isLoading = fetch.isFetching
    this.response = fetch.data
  }
}
